import React from "react";
import dynamic from "next/dynamic";
import Layout from "./layout";

const HomeChoice = dynamic(() => import("../components/HomeChoice"), {
    ssr: false,
});

function HomePage() {
    return (
        <Layout>
            <HomeChoice />
        </Layout>
    );
}

export default HomePage;
